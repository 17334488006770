import React, { Fragment, useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { CircularProgress } from "../../Progress";
import {
  GET_TOOL,
  INSERT_TOOL_EXECUTION_URL,
  UPDATE_TOOL_EXECUTION_URL,
  FILE_DOWNLOAD_API_URL,
  EXTERNAL_TOOL_SUBMIT_URL,
  INTERNAL_TOOL_GRID_UPDATE_URL,
} from "../../../api/paths";
import { FormType } from "./Type";
import {
  BuildNewFormHelper,
  BuildUpdateFormHelper,
} from "./helper/Forms/BuildForm";
import { AuthElement } from "./helper/Forms/BuildForm/Auth";
import { EditForm } from "./helper/Forms/EditForm";
import { SubmitForm } from "./helper/Forms/SubmitForm";
import { EditDateDaysElement } from "./helper/Forms/EditForm/DateDaysPicker";
import { EditNumberElement } from "./helper/Forms/EditForm/NumberElement";
import { IElementUpdate } from "./interface/IElementUpdate";
import { ValidationHelper } from "./helper/Forms/Validation";
import { IForm } from "./interface/IForm";
import { ITool } from "./interface/ITool";
import { ISignout } from "./interface/ISignout";
import { UI_BTN_ACTIONS, UI_ELEMENT_TYPES } from "../config";
import { UtilHelper } from "./helper/Utils";
import { withSnackbar } from "../../../HOC";
import {
  catchError,
  getErrorStatusCode,
} from "../../../helpers/ErrorHandler.component";
import { FormElementHelper } from "./helper/Utils/Element";
import { IFormElementValue } from "./interface/IFormElementValue";
import SnackbarAlert from "../../Snackbar/SnackbarAlert.component";
import { Axios } from "../../../api/service/Axios";
import { LogService } from "../../../api/service/Log";
import { RequestContentTypes } from "../../../api/service/ContentTypes";
import { ParentChildElementUtil } from "./helper/Utils/ParentChildElement";
import { AccessComponent } from "../../Access";
import { GAEvent } from "../../../helpers/GA";
import { GOOGLE_ANALYSTICS_ACTIONS } from "../../../helpers/GA/GoogleAnalysticsUtils";
import { RouterPrompt } from "../../Dialogs/Prompt";

type props = {
  toolId: number;
  toolVersion: string;
  toolName: string;
  toolFormData: any;
  backBtnTitle: string;
  handleBackButton: any;
  toolData: any;
  jobId: any;
  onSubmitChange: any;
  signoutConfig: ISignout;
};
type ESnackbarAlert = "success" | "error" | "info" | "warning";
const utilHelper = new UtilHelper();

const ToolFormComponent = (props: props): JSX.Element => {
  const history = useHistory();
  const [tool, setToolData] = useState<ITool | null>(null);
  const InitialReduxData = useSelector((state: any) => state.initial);
  const bundle = InitialReduxData.bundle;
  const UserID = InitialReduxData.userInfo.userId;

  const { oktaAuth } = useOktaAuth();

  const DefaultErrorMessage = `Request submission failed. Please try again later. If the error persists, please contact the support at <a href="mailTo:${process.env.REACT_APP_SUPPORT_DL}">${process.env.REACT_APP_SUPPORT_DL}</a>.`;

  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const [error, setError] = useState<boolean>(false);
  const [unAuthError, setUnAuthError] = useState<boolean>(false);
  const [initiaLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ishavingParent,setIshavingParent] = useState<any>("");
  const [formDirty, setFormDirty] = useState({
    prev: false,
    new: false,
  });
  let dataSetValue = 0;

  // Set Snackbar Alerts
  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const InitialDataLoad = async (): Promise<void> => {
    setInitialLoading(true);
    setError(false);
    setFormDirty({
      prev: false,
      new: false,
    });

    try {
      const Response: any = await Axios.get(
        GET_TOOL(props.toolId, props.toolName, props.toolVersion)
      );
      const buildNewForm = new BuildNewFormHelper();
      const authElement = new AuthElement();

      const AuthGroups =
        (await authElement.getAuthGroups(Response.data?.forms, props.toolId)) ||
        [];

      if (
        !_.isEqual(AuthGroups.authGroups.length, 0) &&
        authElement.isAnyAuthGroupIsNull(AuthGroups.authGroups)
      ) {
        const ToolData: ITool = {
          ...Response.data,
          initialForms: Response.data?.forms,
          emptyForm: [],
          datasetForm: [],
          forms: [],
          authGroups: AuthGroups.authGroups || [],
        };

        const errorMessage = authElement.isAnyAuthGroupHasError(
          AuthGroups.authGroups
        );
        if (!_.isEmpty(errorMessage)) {
          setAlert(errorMessage, "error");
        }

        setToolData(ToolData);
        return setInitialLoading(false);
      }

      const UpdatedForms = await buildNewForm.form(AuthGroups.forms);
      const Data: ITool = {
        ...Response.data,
        initialForms: AuthGroups.forms,
        initForms: AuthGroups.forms,
        forms: UpdatedForms,
        datasetForm: UpdatedForms,
        emptyForm: UpdatedForms,
        authGroups: AuthGroups.authGroups || [],
      };

      setToolData(Data);
      return setInitialLoading(false);
    } catch (err: any) {
      setInitialLoading(false);
      const StatusCode = getErrorStatusCode(err);
      if (!_.isNull(StatusCode) && _.isEqual(StatusCode, 403)) {
        setUnAuthError(true);
        setError(true);
        return;
      }
      const errorMessage = catchError(err);
      setAlert(errorMessage, "error");
      setError(true);
    }
  };

  useEffect(() => {
    setToolData(props.toolData);
  }, []);

  if (initiaLoading) return <CircularProgress />;

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  if (error) {
    return (
      <Fragment>
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
        {unAuthError && (
          <AccessComponent
            heading={bundle.UNAUTH_TOOL_HEADING}
            description={bundle.UNAUTH_TOOL_DESCRIPTION}
          />
        )}
      </Fragment>
    );
  }

  // Validate element and forms
  // Trigger on OnBlur event - Applicable for email
  const onValidateForms = async (element: IElementUpdate): Promise<any> => {
    const ElementValue: IFormElementValue = {
      currentValue: element.value,
      previousValue: element.formElement.value,
    };

    let isLoadButtonClicked = _.isUndefined(element.isLoadDataButtonClicked)
      ? false
      : element.isLoadDataButtonClicked;

    if (!_.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)) {
      isLoadButtonClicked = true;
    }
    const validationHelper: ValidationHelper = new ValidationHelper();
    if (
      _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) &&
      !element.formElement.is_email &&
      !validationHelper.isPreviousAndCurrentValueAreSame(
        element.formElement,
        ElementValue
      )
    ) {
      let UpdateForms = validationHelper.isValidForms(tool?.forms || []);
      if (
        _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) &&
        element.formElement.is_number
      ) {
        const editNumberElement: EditNumberElement = new EditNumberElement();
        UpdateForms = editNumberElement.updateFormsForNumberRelationship(
          UpdateForms || [],
          element
        );
      }

      if (isLoadButtonClicked) {
        return ParentChildElementValidation(UpdateForms || [], element);
      }
    }

    let UpdatedForms = await validationHelper.isValidElement(
      tool?.forms! || [],
      element
    );
    if (
      _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) &&
      element.formElement.is_number
    ) {
      const editNumberElement: EditNumberElement = new EditNumberElement();
      UpdatedForms = editNumberElement.updateFormsForNumberRelationship(
        UpdatedForms || [],
        element
      );
    }

    let ValidateForms = validationHelper.isValidForms(UpdatedForms);
    if (
      _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) &&
      element.formElement.is_number
    ) {
      const editNumberElement: EditNumberElement = new EditNumberElement();
      ValidateForms = editNumberElement.updateFormsForNumberRelationship(
        ValidateForms || [],
        element
      );
    }

    setToolData((previousData: any) => ({
      ...previousData,
      forms: ValidateForms,
    }));

    if (
      !validationHelper.isPreviousAndCurrentValueAreSame(
        element.formElement,
        ElementValue
      ) &&
      isLoadButtonClicked
    ) {
      return ParentChildElementValidation(ValidateForms, element);
    }
  };
  const checkDirtyForm = (element: IElementUpdate): void => {
    if (formDirty.new) {
      return;
    }
    if (
      (_.isEqual(
        element.formElement.type_id,
        UI_ELEMENT_TYPES.DATERANGE_INPUT
      ) ||
        _.isEqual(
          element.formElement.type_id,
          UI_ELEMENT_TYPES.DATETIME_INPUT
        )) &&
      utilHelper.compareStrings(element.value, element.formElement.value)
    ) {
      return;
    }

    setFormDirty({
      prev: true,
      new: true,
    });
  };

  // on Field value change
  const onChange = async (
    element: IElementUpdate,
    dataSet?: number
  ): Promise<any> => {
    setIshavingParent(element.formElement?.id);
    const elementHelper = new FormElementHelper();

    if (element.deleteElements &&
      element.formElement?.deletebyIds &&
      _.gte(element.formElement?.deletebyIds.length, 0)) {
      const updatedFormelements = elementHelper.deleteFormElement(
        tool?.forms || [],
        element,
      );
      return setToolData((previousData: any) => ({
        ...previousData,
        forms: updatedFormelements,
      }));
    }

    
    checkDirtyForm(element);

    if (dataSet) {
      dataSetValue = dataSet;
    }

    if (!_.isUndefined(dataSet) && _.isEqual(dataSet, 1)) {
      const IsParentEmpty = elementHelper.isAnyParentEmpty(
        tool?.forms || [],
        element.formElement.parent_elements
      );

      if (IsParentEmpty) {
        const InitialData = tool?.emptyForm;

        return setToolData((previousData: any) => ({
          ...previousData,
          forms: InitialData,
        }));
      } else {
        const updatedFormValue = elementHelper.mapElementForResetDataset(
          tool?.datasetForm || [],
          tool?.forms || []
        );
        return setToolData((previousData: any) => ({
          ...previousData,
          forms: updatedFormValue,
        }));
      }
    }else if(_.isEqual(element.dynamicElement?.action,UI_BTN_ACTIONS.ADD) 
      || _.isEqual(element.dynamicElement?.action,UI_BTN_ACTIONS.DELETE)){
        const editForm: EditForm = new EditForm();
        const UpdatedForms: any = _.isEqual(element.dynamicElement?.action,UI_BTN_ACTIONS.ADD) ? 
        await editForm.updateFormsForAdd(tool || [], element.formId) : 
        await editForm.updateFormsForRemove(tool?.forms! || []);
        return setToolData((previousData: any) => ({
          ...previousData,
          forms: UpdatedForms,
        }));
      }   
    else if (
      !_.isUndefined(dataSet) &&
      _.isEqual(dataSet, 0) &&
      !_.isNull(element.formElement.dataset_apiurl) &&
      _.isEqual(element.formElement.is_dataset, 1) &&
      _.isObject(element.value)
    ) {
      return EditFormConfiguration(element, dataSetValue);
    } else if (!_.isUndefined(dataSet) && _.isEqual(dataSet, 0)) {
      const updatedFormValue = elementHelper.mapElementForResetDataset(
        tool?.datasetForm || [],
        tool?.forms || []
      );

      return setToolData((previousData: any) => ({
        ...previousData,
        forms: updatedFormValue,
      }));
    } else if (
      _.isUndefined(dataSet) &&
      !_.isNull(element.formElement.dataset_apiurl) &&
      _.isEqual(element.formElement.is_dataset, 1) &&
      _.isObject(element.value)
    ) {

      return EditFormConfiguration(element, dataSetValue);
    }


    else {
      const ElementValue: IFormElementValue = {
        currentValue: element.value,
        previousValue: element.formElement.value,
      };

      const validationHelper: ValidationHelper = new ValidationHelper();
      const editForm: EditForm = new EditForm();
      let UpdatedForms: any = await editForm.updateForms(
        tool?.forms! || [],
        element,
        ElementValue
      );

      if (
        _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) &&
        element.formElement.is_email
      ) {
        UpdatedForms = validationHelper.isValidForms(UpdatedForms);
        return setToolData((previousData: any) => ({
          ...previousData,
          forms: UpdatedForms,
        }));
      }

      if (_.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.ADD_BUTTON)) {
        UpdatedForms = await editForm.addFormElements(
          tool?.forms! || [], element);

      }

      if (!_.isUndefined(element.newOption)) {
        UpdatedForms = await editForm.updateElementDataSource(
          UpdatedForms,
          element
        );
      }

      UpdatedForms = await validationHelper.isValidElement(
        UpdatedForms,
        element
      );

      if (
        _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)
      ) {
        const editDateDaysElement = new EditDateDaysElement();
        if (element.formElement.is_number) {
          UpdatedForms = editDateDaysElement.updateFormsForDaysPicker(
            UpdatedForms || [],
            element
          );
        } else {
          UpdatedForms = editDateDaysElement.updateFormsForDatePicker(
            UpdatedForms || [],
            element
          );
        }
      }

      if (
        _.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) &&
        element.formElement.is_number
      ) {
        const editNumberElement = new EditNumberElement();
        UpdatedForms = editNumberElement.updateFormsForNumberRelationship(
          UpdatedForms || [],
          element
        );
      }
      UpdatedForms = elementHelper.findDependentElements(UpdatedForms, element, dataSetValue);
      const ValidateForms = validationHelper.isValidForms(UpdatedForms);
      setToolData((previousData: any) => ({
        ...previousData,
        forms: ValidateForms,
      }));

      let UpdatedValidatedForm: any = [];

      let isLoadButtonClicked = _.isUndefined(element.isLoadDataButtonClicked)
        ? false
        : element.isLoadDataButtonClicked;

      if (
        !_.isEqual(element.formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
      ) {
        isLoadButtonClicked = true;
      }

      if (
        isLoadButtonClicked &&
        !validationHelper.isPreviousAndCurrentValueAreSame(
          element.formElement,
          ElementValue
        )
      ) {
        UpdatedValidatedForm = await ParentChildElementValidation(
          UpdatedForms,
          element
        );
      }
      const FormData = _.isEmpty(UpdatedValidatedForm)
        ? ValidateForms
        : UpdatedValidatedForm;

      const IsParentValueForDataSetFieldChanged =
        await elementHelper.IsParentForDataSetFieldChanged(FormData, element);

      const IsDependsOnValueForDatasetFieldChanged = await elementHelper.IsDependsOnForDataSetFieldChanged(FormData, element);


      if (!_.isEqual(ElementValue.previousValue, ElementValue.currentValue)) {
        if (IsParentValueForDataSetFieldChanged) {
          const updatedFormValue = elementHelper.mapElementForResetDataset(
            tool?.datasetForm || [],
            FormData || [],
            element.formElement.id  
          );

          return setToolData((previousData: any) => ({
            ...previousData,
            forms: updatedFormValue,
          }));
        }
        if(IsDependsOnValueForDatasetFieldChanged){
          const updatedFormValue = elementHelper.mapElementForResetDependsOnDataset(
            tool?.datasetForm || [],
            FormData || [],  
            element.formElement.id  
          );

          return setToolData((previousData: any) => ({
            ...previousData,
            forms: updatedFormValue,
          }));
        }  
      }
    }
    setIshavingParent(null);
  };

  const EditFormConfiguration = async (
    element: IElementUpdate,
    dataSet?: number
  ): Promise<any> => {
    const ElementValue: IFormElementValue = {
      currentValue: element.value,
      previousValue: element.formElement.value,
    };

    const validationHelper: ValidationHelper = new ValidationHelper();

    const editForm: EditForm = new EditForm();

    let UpdatedForms: any = await editForm.updateForms(
      tool?.forms! || [],
      element,
      ElementValue
    );

    UpdatedForms = await validationHelper.isValidElement(UpdatedForms, element);

    const elementHelper = new FormElementHelper();

    UpdatedForms = elementHelper.findDependentElements(UpdatedForms, element, dataSet);

    const ValidateForms = validationHelper.isValidForms(UpdatedForms);


    setToolData((previousData: any) => ({
      ...previousData,
      forms: ValidateForms,
    }));

    try {
      let Response_Data: any = [];

      const ParentElements = element.formElement.parent_elements;

      const buildUpdateFormHelper: BuildUpdateFormHelper =
        new BuildUpdateFormHelper();
      setLoading(true);

      const POSTData = elementHelper.getParentValuesForPOSTRequest(
        tool?.forms || [],
        ParentElements
      );

      const POSTParam = {
        [_.toLower(element.formElement.labelId)]: element.value,
        ...POSTData,
      };


      Response_Data = await Axios.post(
        element.formElement.dataset_apiurl,
        POSTParam
      );

      const updatedFormValue = elementHelper.mapElementValuesForEdit(
        tool?.initialForms || [],
        Response_Data.data,
        ValidateForms || []
      );

      UpdatedForms = await buildUpdateFormHelper.form(updatedFormValue);

      setLoading(false);
      return setToolData((previousData: any) => ({
        ...previousData,
        forms: UpdatedForms,
      }));
    } catch (err) {
      const errorMessage = catchError(err);
      setLoading(false);
      setAlert(errorMessage, "error");
    }
  };

  const ParentChildElementValidation = async (
    updatedForms: any,
    element: any
  ): Promise<any> => {
    const validationHelper: ValidationHelper = new ValidationHelper();
    const parentChildEle: ParentChildElementUtil = new ParentChildElementUtil();
    const elementHelper = new FormElementHelper();
    
    setLoading(true);
    let UpdatedForms = parentChildEle.findChildLoadingCell(updatedForms,
      element);

    // Call API if any child is associated
    UpdatedForms = await parentChildEle.findParentElements(
      UpdatedForms,
      element
    );

    setToolData((previousData: any) => ({
      ...previousData,
      forms: UpdatedForms,
    }));


    UpdatedForms = await parentChildEle.findChildElements(
      UpdatedForms || [],
      element
    );

    UpdatedForms = parentChildEle.findChildLoadingCell(UpdatedForms,
      element);

    setToolData((previousData: any) => ({
      ...previousData,
      forms: UpdatedForms,
    }));

    // **To show error message if no AD/BD access for dataset Client
    const getErrorMessegeForDD = await elementHelper.getAPIErrorMessegeForDD(
      UpdatedForms
    );
    const GetChildElements = elementHelper.findChildElementsForDataSet(
      UpdatedForms,
      element.formElement.id
    );

    if (!_.isEmpty(GetChildElements) && !_.isEqual(getErrorMessegeForDD, "")) {
      setAlert(getErrorMessegeForDD, "error");
    }
    UpdatedForms = await validationHelper.validateElements(UpdatedForms);
    setLoading(false);
    const ValidatedForms = validationHelper.isValidForms(UpdatedForms);

    setToolData((previousData: any) => ({
      ...previousData,
      forms: ValidatedForms,
    }));

    return ValidatedForms;
  };

  const UpdateToolExecutionHistory = async (
    toolExeHistoryID: number,
    statusMessage: string,
    statusCode: number,
    statusResponse: string,
    toolID?: number,
    toolName?: string,
    toolVersion?: string,
  ): Promise<any> => {
    try {
      const Message = _.isEqual(
        (statusMessage || "").toString().trim().length,
        0
      )
        ? bundle.DEFAULT_TOOLFORM_SUCCESS_MESSAGE
        : statusMessage;

      const Response = await Axios.put(
        `${UPDATE_TOOL_EXECUTION_URL}${toolExeHistoryID}`,
        {
          statusmessage: Message,
          statuscode: statusCode,
          status: statusResponse,
          toolid: toolID,
          toolname: toolName,
          toolversion: toolVersion,
          isportalrequest: true,
        }
      );
      return Response.data;
    } catch (err: any) {
      const errorMessage = catchError(err);
      setAlert(errorMessage, "error");
      return null;
    }
  };

  const getHistoryInsertData = (
    forms: any,
    PortalData: any,
    userID: any,
    isDatasetUpdatedRequest: any,
    event: any,
    tooldata?: any
  ): any => {
    return {
      toolid: tool?.tool_id,
      toolname: tool?.tool_name,
      portaldata: forms,
      formdata: PortalData,
      toolversion: tool?.tool_version,
      userid: userID,
      statusmessage: "The request is in progress.",
      event: event,
      status: "In Progress",
      isserviceaccountauth: tool?.is_service_account_auth,
      isupdateapi: isDatasetUpdatedRequest,
      isportalrequest: true,
      isgrid: tooldata.is_grid,
      isschedule: tooldata.is_schedule,
      ishistory: tooldata.is_history
    };
  };

  // Submit data
  const onSubmit = async (forms: IForm[], callback: any): Promise<any> => {
    const submitForm: SubmitForm = new SubmitForm();
    const elementHelper = new FormElementHelper();

    const IsDatasetUpdatedRequest = await elementHelper.IsDatasetUpdatedRequest(
      tool?.forms
    );

    if (
      !utilHelper.isEmptyValue(tool?.api_endpoint) ||
      !utilHelper.isEmptyValue(tool?.update_api_endpoint)
    ) {

      setLoading(true);
      setFormDirty((prevState) => ({
        ...prevState,
        new: false,
      }));
      try {
        const isMultiForm = forms.some(x=> !(_.isEqual(x.id % 1, 0)) || !(_.isEqual(x.parentId,null)));
        const { formData, encryptedKeys } = isMultiForm ? submitForm.buildFormDataForMultiForm(forms) :submitForm.buildFormData(forms);

        const PortalData = {
          toolname: tool?.tool_name,
          toolversion: tool?.tool_version,
          formdata: formData,
          toolid: tool?.tool_id,
          userid: UserID,
          encryptedKeys,
        };

        const CustomHeaders = submitForm.getHeadersValueFromAuthElement(forms);
        const portalDataform = submitForm.buildPortalData(forms);
        let payloadData;
        if (props.toolData.is_grid && !props.toolData.is_schedule) {
          payloadData = getHistoryInsertData(portalDataform, PortalData, UserID, IsDatasetUpdatedRequest, "Adhoc", props.toolData);
        }
        else if (props.toolData.is_grid && props.toolData.is_schedule) {
          payloadData = getHistoryInsertData(portalDataform, PortalData, UserID, IsDatasetUpdatedRequest, "Schedule", props.toolData);
        }
        else {
          payloadData = getHistoryInsertData(portalDataform, PortalData, UserID, IsDatasetUpdatedRequest, "Submit", props.toolData);
        }

        const InsertData = await Axios.post(INSERT_TOOL_EXECUTION_URL, payloadData);
        const ToolExeHistoryID = InsertData.data.historyId;

        try {
          const ContentType = RequestContentTypes.JSON;

          let CFData = InsertData.data.apiResponse;

          if (_.isNull(CFData)) {
            return;
          }
          let apiEndpoint = IsDatasetUpdatedRequest || props.toolData.is_Edit
            ? tool?.update_api_endpoint || ""
            : tool?.api_endpoint || "";

          let data = {};
          if (_.isEqual(props.toolData.is_Edit, true) && props.toolData.is_grid) {
            data = {
              jobId: props.jobId,
              isSchedule: props.toolData.is_schedule,
              isGrid: props.toolData.is_grid,
              portaldata_history: portalDataform,
              formdata_history: PortalData,
              isportalconsumerrequest: true,
              ...InsertData.data.formData,
            };
          }
          else if (!props.toolData.is_Edit && props.toolData.is_grid) {
            data = {
              isSchedule: props.toolData.is_schedule,
              isGrid: props.toolData.is_grid,
              portaldata_history: portalDataform,
              formdata_history: PortalData,
              isportalconsumerrequest: true,
              ...InsertData.data.formData,
            };
          }
          else {
            data = {
              isSchedule: props.toolData.is_schedule,
              isGrid: props.toolData.is_grid,
              ...InsertData.data.formData,
            };
          }
          if ((!props.toolData.is_grid || !props.toolData.is_schedule) && props.toolData.is_history) {
            data = {
              ...data,
              portaldata_history: portalDataform,
              formdata_history: PortalData,
              isportalconsumerrequest: true
            };
          }
          if (!props.toolData.is_history && !props.toolData.is_grid && !props.toolData.is_schedule) {
            data = _.omit(data, ["portaldata_history", "formdata_history"]); 
          }    

          if (tool?.is_service_account_auth) {
            apiEndpoint = EXTERNAL_TOOL_SUBMIT_URL;
            data = {
              formData: data,
              toolId: tool?.tool_id,
              toolName: tool?.tool_name,
              serviceUrl: IsDatasetUpdatedRequest
                ? tool?.update_api_endpoint || ""
                : tool?.api_endpoint || "",
              customHeaders: {
                ...CustomHeaders,
                "x-portal-okta-token": oktaAuth.getIdToken(),
              },
              token: InsertData.data.token,
            };
          }

          if (_.isEqual(props.toolData.is_Edit, true)) {
            CFData = await Axios.put(INTERNAL_TOOL_GRID_UPDATE_URL(apiEndpoint, props.jobId), data, {
              headers: {
                "Content-Type": ContentType,
                ...CustomHeaders,
              },
            });
          }
          else {
            CFData = await Axios.post(apiEndpoint, data, {
              headers: {
                "Content-Type": ContentType,
                ...CustomHeaders,
              },
            });
          }

          const { filename, message, fileurl, warning, info } = CFData.data;
          const StatusCode = CFData.status;
          let statusType;
          if (warning) {
            statusType = "Warning";
          } else {
            statusType = info ? "Info" : "Success";
          }
          if (props.toolData.is_grid) {
            props.onSubmitChange(CFData.data.message);
            return;
          }
          if (!props.toolData.is_grid && props.toolData.is_history) {
            callback(false);
            setLoading(false);
            return setAlert(CFData.data.message, "success");
          } 
          else {
            const UpdatedData = await UpdateToolExecutionHistory(
              ToolExeHistoryID,
              message,
              StatusCode,
              statusType,
              tool?.tool_id,
              tool?.tool_name,
              tool?.tool_version
            );

            if (_.isNull(UpdatedData)) {
              setLoading(false);
              return;
            }

            if (
              tool?.is_file_download_api &&
              !_.isUndefined(fileurl) &&
              !_.isNull(fileurl) &&
              !_.isUndefined(filename) &&
              !_.isNull(filename)
            ) {
              window.location.href = `${process.env.REACT_APP_BASE_URL
                }${FILE_DOWNLOAD_API_URL}?filename=${filename}&fileurl=${encodeURIComponent(
                  fileurl
                )}`;
            }
            callback(false);
            setLoading(false);

            if (warning) {
              GAEvent(
                tool?.tool_name,
                GOOGLE_ANALYSTICS_ACTIONS.TOOL_SUBMIT,
                "Warning"
              );
              return setAlert(UpdatedData.message, "warning");
            } else if (info) {
              GAEvent(
                tool?.tool_name,
                GOOGLE_ANALYSTICS_ACTIONS.TOOL_SUBMIT,
                "Info"
              );
              setLoading(false);
              return setAlert(UpdatedData.message, "info");
            }
            GAEvent(
              tool?.tool_name,
              GOOGLE_ANALYSTICS_ACTIONS.TOOL_SUBMIT,
              "Success"
            );

            return setAlert(UpdatedData.message, "success");
          }
        } catch (err: any) {
          setFormDirty((prevState) => ({
            ...prevState,
            new: prevState.prev,
          }));
          GAEvent(
            tool?.tool_name,
            GOOGLE_ANALYSTICS_ACTIONS.TOOL_SUBMIT,
            "Failed"
          );
          callback(true);
          if (err.response && props.toolData.is_grid) {
            const errorMessage = catchError(err);
            setLoading(false);
            return setAlert(errorMessage, "error");
          }
          if (!err.response) {
            const msg = err.message;
            const TLData = await UpdateToolExecutionHistory(
              ToolExeHistoryID,
              msg,
              500,
              "Failed",
              tool?.tool_id,
              tool?.tool_name,
              tool?.tool_version
            );
            setLoading(false);
            if (_.isNull(TLData)) {
              return;
            }
            return setAlert(DefaultErrorMessage, "error");
          }
          const errorMessage = catchError(err);
          const Message = _.isEqual(
            (errorMessage || "").toString().trim().length,
            0
          )
            ? DefaultErrorMessage
            : errorMessage;

          const statusCode = err.response.status;
          const TData = await UpdateToolExecutionHistory(
            ToolExeHistoryID,
            Message,
            statusCode,
            "Failed",
            tool?.tool_id,
            tool?.tool_name,
            tool?.tool_version
          );
          setLoading(false);
          if (_.isNull(TData)) {
            return;
          }
          if (_.isEqual(statusCode, 403)) {
            return setAlert(Message, "info");
          }
          return setAlert(Message, "error");
        }
      } catch (err: any) {
        setFormDirty((prevState) => ({
          ...prevState,
          new: prevState.prev,
        }));

        GAEvent(
          tool?.tool_name,
          GOOGLE_ANALYSTICS_ACTIONS.TOOL_SUBMIT,
          "Failed"
        );
        callback(true);
        setLoading(false);
        if (!err.response) {
          return setAlert(DefaultErrorMessage, "error");
        }
        const errorMessage = catchError(err);
        const Message = _.isEqual(
          (errorMessage || "").toString().trim().length,
          0
        )
          ? DefaultErrorMessage
          : errorMessage;
        return setAlert(Message, "error");
      }
    }
  };
  const onDialogClose = (): void => {
    if (!_.isNull(tool)) {
      GAEvent(
        tool.tool_name,
        GOOGLE_ANALYSTICS_ACTIONS.BUTTON_CLICKED,
        "Cancel"
      );
    }
    history.push("/");
  };

  if (_.isNull(tool)) return <div>No tool configuration found.</div>;

  const handleNewForm = (): void => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
    GAEvent(
      tool?.tool_name,
      GOOGLE_ANALYSTICS_ACTIONS.BUTTON_CLICKED,
      "New Form"
    );
    LogService({
      action: "click",
      toolId: tool?.tool_id,
      toolName: tool?.tool_name,
      toolVersion: tool?.tool_version,
      name: "New Form",
      toolTypeId: tool?.tool_type_id,
      isportalrequest: true
    });
    InitialDataLoad();
  };

  return (
    <Fragment>
      {loading && <CircularProgress />}
      <FormType
        tool={tool}
        handleNewForm={handleNewForm}
        onChange={onChange}
        onSubmit={onSubmit}
        onValidateForms={onValidateForms}
        backBtnTitle={props.backBtnTitle}
        handleBackButton={props.handleBackButton}
        loading={loading}
        signoutConfig={props.signoutConfig}
        ishavingParent={ishavingParent}
      />
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
       <RouterPrompt
        when={formDirty.new}
        onCancel={() => { }}
        onSubmit={() => {
          onDialogClose();
          return true;
        }}
      />


    </Fragment>
  );
};

export default withSnackbar(ToolFormComponent);
