import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Card, Grid, Theme, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import style from "./style";
import StatusIndicator from "../../../../UI/StatusIndicator";
import { IToolkey } from "../../../../UI/Elements/Forms/interface/IToolKey";
import { LogService } from "../../../../api/service/Log";
import { GAEvent } from "../../../../helpers/GA";
import { GOOGLE_ANALYSTICS_ACTIONS } from "../../../../helpers/GA/GoogleAnalysticsUtils";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { Axios } from "../../../../api/service/Axios";
import { ADD_FAVORITE_URL, REMOVE_FAVORITE_URL } from "../../../../api/paths";
import SnackbarAlert from "../../../../UI/Snackbar/SnackbarAlert.component";
import { updateToolState } from "../../../../store/actions/Automation/ToolList";
import { getToolList } from "../../../../store/actions/Automation";
import { IToolList } from "../../../../store/reducers/interfaces/IToolList";
import Highlighter from "react-highlight-words";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  Toolkey: IToolkey;
  index: number;
};

export const ToolTile = (props: props): React.JSX.Element => {//NOSONAR
  const { Toolkey, index } = props;
  const classes = useStyles();
  const [starIcon, setStarIcon] = useState(Toolkey.is_favorite);
  const history = useHistory();
  const SelectedFilterValues: any = localStorage.getItem("filterCategories");
  const FilterJSON = JSON.parse(SelectedFilterValues);
  const searchText = FilterJSON.search;

  const REDUX_STATE = useSelector((state: any) => ({
    FILTER_STATE: state.filter,
    BUNDLE: state.initial.bundle,
    TOOL_LIST: state.toolList,
  }));

  const dispatch = useDispatch();

  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  type ESnackbarAlert = "success" | "error" | "info" | "warning";

  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  //Add Tool to Favorite
  const addFavoriteHandleClick = async (
    e: any,
    toolkey: IToolkey
  ): Promise<void> => {
    e.stopPropagation();
    setStarIcon(true);
    await Axios.post(ADD_FAVORITE_URL, {
      toolid: toolkey.tool_id,
    })
      .then()
      .catch(() => {
        setAlert(REDUX_STATE.BUNDLE.TOOLKIT_ADD_FAVORITE_ERROR_MSG, "error");
        setStarIcon(false);
      });
  };

  //Remove tool from favorite
  const removeFavoriteHandleClick = async (
    e: any,
    toolkey: IToolkey
  ): Promise<void> => {
    e.stopPropagation();
    if (REDUX_STATE.FILTER_STATE.filter.include_favorite) {//NOSONAR
      dispatch(updateToolState(toolkey));
      await Axios.remove(REMOVE_FAVORITE_URL(toolkey.tool_id))
        .then()
        .catch(() => {
          setAlert(
            REDUX_STATE.BUNDLE.TOOLKIT_REMOVE_FAVORITE_ERROR_MSG,
            "error"
          );

          const ToolListParameters: IToolList = {
            ...REDUX_STATE.FILTER_STATE.filter,
            page_number: 0,
          };

          dispatch(getToolList(ToolListParameters));
          setStarIcon(true);
        });
    } else {//NOSONAR
      setStarIcon(false);
      await Axios.remove(REMOVE_FAVORITE_URL(toolkey.tool_id))
        .then()
        .catch(() => {
          setAlert(
            REDUX_STATE.BUNDLE.TOOLKIT_REMOVE_FAVORITE_ERROR_MSG,
            "error"
          );
          setStarIcon(true);
        });
    }
  };

  const handleClick = (toolKey: IToolkey): void => {
    LogService({
      action: "click",
      toolId: toolKey.tool_id,
      toolName: toolKey.tool_name,
      toolVersion: toolKey.tool_version,
      name: "Open Tile",
      toolTypeId: toolKey.tool_type_id,
      isportalrequest: true
    });
    GAEvent(toolKey.tool_name, GOOGLE_ANALYSTICS_ACTIONS.CLICK, "Open Tile");

    history.push(
      `/tool?id=${encodeURIComponent(
        toolKey.tool_id
      )}&name=${encodeURIComponent(
        toolKey.tool_name
      )}&version=${encodeURIComponent(toolKey.tool_version)}`
    );
  };

  const PLATFORMS = !_.isUndefined(Toolkey.platform)//NOSONAR
    ? Toolkey.platform.join(" • ")//NOSONAR
    : "";//NOSONAR

  const TRUNCATESD_NAME = _.gte(Toolkey.tool_name.length, 60)//NOSONAR
    ? Toolkey.tool_name.substring(0, 52) + "..."//NOSONAR
    : Toolkey.tool_name;//NOSONAR

  const ToolLogo = _.isNull(Toolkey.tool_type_icon_path)//NOSONAR
    ? ""//NOSONAR
    : Toolkey.tool_type_icon_path;//NOSONAR

  const IconStar = (): any => {
    if (Toolkey.under_maintenance) {//NOSONAR
      return <StarIcon
        className={classes.starIcon}
      />;
    }

    if (starIcon) {//NOSONAR
      return (
        <Tooltip title="Remove tool from Favorites" placement="bottom">
          <StarIcon
            className={classes.starIcon}
            onClick={(e) => removeFavoriteHandleClick(e, Toolkey)}
          />
        </Tooltip>
      );
    } else {//NOSONAR
      return (
        <Tooltip title="Add tool to Favorites" placement="bottom">
          <StarBorderIcon
            className={classes.starIcon}
            onClick={(e) => addFavoriteHandleClick(e, Toolkey)}
          />
        </Tooltip>
      );
    }
  };

  const getMaintainenceStatus = (): any => {
    if (Toolkey.under_maintenance) {//NOSONAR
      return "MAINTENANCE";
    }
    return Toolkey.tool_label;
  };

  const getTooltipTitle = (): any => {
    if (Toolkey.under_maintenance) {//NOSONAR
      return "";
    }
    return `${REDUX_STATE.BUNDLE.TOOLBOX_PLATFORM_TOOLTIP} : ${PLATFORMS}`;
  };

  return !_.isUndefined(Toolkey) ? (//NOSONAR
    <Grid
      key={index}
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      xl={4}
      justifyContent="center"
    >
      <Card
        className={classNames(classes.cardContainer, {
          [classes.disabledTool]: Toolkey.under_maintenance
        })}
        onClick={() => {
          if (!Toolkey.under_maintenance) {//NOSONAR
            handleClick(Toolkey);
          }
        }}
      >
        <Box display="flex" className={classes.toolLogo}>
          <Box flexGrow={1}>
            <img src={ToolLogo} width="40px" height="40px" />
          </Box>
          <Box alignSelf="center">
            <StatusIndicator status={getMaintainenceStatus()} />
          </Box>
        </Box>
        <div className={classes.toolTitle}>
          <Highlighter
            className={classes.name}
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={TRUNCATESD_NAME}
          />
          <span className={classes.toolVersion}>{Toolkey.tool_version}</span>
        </div>
        <div className={classes.toolDescription}>{Toolkey.short_descr}</div>
        <div className={classes.cont}>
          <Tooltip
            title={getTooltipTitle()}
            placement="bottom-start"
          >
            <div aria-label={"platform"} className={classes.toolInfo}>
              {PLATFORMS}
            </div>
          </Tooltip>

          <div className={classes.starDiv}>
            <IconStar />
          </div>
        </div>
      </Card>
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
    </Grid>
  ) : (
    <span />
  );
};
