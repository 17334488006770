import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { Grid, Button, Theme } from "@material-ui/core";
import style from "../style";

import { ITool } from "../../../../../UI/Elements/Forms/interface/ITool";
import { LogService } from "../../../../../api/service/Log";
import { GAEvent } from "../../../../../helpers/GA";
import { GOOGLE_ANALYSTICS_ACTIONS } from "../../../../../helpers/GA/GoogleAnalysticsUtils";
import GeneralConfig from "../../../../GeneralConfig";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: ITool;
};

// Tool Info Component
export const ToolInfo = (props: props): React.JSX.Element => {
  const { data } = props;
  const classes = useStyles();
  const bundle = useSelector((state: any) => state.initial.bundle);
  const initial = useSelector((state: any) => state.initial);
  const isExternalUser = initial.isExternalUser;

  const onMoreInfoClick = (link: string): void => {
    LogService({
      action: "click",
      toolId: data.tool_id,
      toolName: data.tool_name,
      toolVersion: data.tool_version,
      url: link,
      name: "More Information",
      toolTypeId: data.tool_type_id,
      isportalrequest: true
    });

    GAEvent(
      data.tool_name,
      GOOGLE_ANALYSTICS_ACTIONS.LINK_CLICKED,
      "More Information"
    );
    if (!_.isNull(link)) {
      window.open(link);
    }
  };

  const onButtonClick = (link: string): void => {
    LogService({
      action: "click",
      toolId: data.tool_id,
      toolName: data.tool_name,
      toolVersion: data.tool_version,
      url: link,
      name: "Open Tool",
      toolTypeId: data.tool_type_id,
    });

    GAEvent(
      data.tool_name,
      GOOGLE_ANALYSTICS_ACTIONS.BUTTON_CLICKED,
      "Open Tool"
    );
    const GetToken: any = localStorage.getItem("okta-token-storage");
    const AccessToken: any = JSON.parse(GetToken);
    if (!_.isNull(link)) {
      if (data.is_external_token) {
        window.open(`${link}?token=${AccessToken.idToken?.idToken}`);
      }
      else {
        window.open(link);
      }
    }
  };

  const ToolState = data.tool_state.toLowerCase().trim() || "";

  const addTargetBlank = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const anchors = doc.querySelectorAll("a");
    anchors.forEach(anchor => {
      anchor.setAttribute("target", "_blank");
    });
    return doc.body.innerHTML;
  };

  const containsAnchorTag = data.long_descr.includes("<a");
  const modifiedLongDescr = containsAnchorTag ? addTargetBlank(data.long_descr) : data.long_descr;

  return (
    <Fragment>
      {!_.isString(data) && (
        <div className={classes.toolContent}>
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              className={classes.toolLogo}
            >
              <img src={data.tooltype_icon_path} width="40" height="40" />
              {_.isEqual(ToolState, GeneralConfig.EXTERNAL) &&
                !_.isNull(data.tool_url) &&
                !_.isEqual(data.tool_url, "") && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onButtonClick(data.tool_url)}
                  >
                    <svg
                      width="12px"
                      height="12px"
                      viewBox="0 0 12 12"
                      version="1.1"
                    >
                      <g
                        id="Screens"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Assets"
                          transform="translate(-47.000000, -126.000000)"
                        >
                          <g
                            id="Components/Button/Primary-Icon/Left/Default"
                            transform="translate(33.000000, 114.000000)"
                          >
                            <g
                              id="Label"
                              transform="translate(12.000000, 7.000000)"
                            >
                              <g
                                id="external-link-line"
                                transform="translate(0.000000, 3.000000)"
                              >
                                <polygon
                                  id="Path"
                                  points="0 0 16 0 16 16 0 16"
                                ></polygon>
                                <path
                                  d="M6.66666667,4 L6.66666667,5.33333333 L3.33333333,5.33333333 L3.33333333,12.6666667 L10.6666667,12.6666667 L10.6666667,9.33333333 L12,9.33333333 L12,13.3333333 C12,13.7015232 11.7015232,14 11.3333333,14 L2.66666667,14 C2.29847683,14 2,13.7015232 2,13.3333333 L2,4.66666667 C2,4.29847683 2.29847683,4 2.66666667,4 L6.66666667,4 Z M14,2 L14,7.33333333 L12.6666667,7.33333333 L12.6666667,4.27533333 L7.47133333,9.47133333 L6.52866667,8.52866667 L11.7233333,3.33333333 L8.66666667,3.33333333 L8.66666667,2 L14,2 Z"
                                  id="Shape"
                                  fill="#F7F8FA"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span style={{ paddingLeft: 8 }}>
                      {bundle.LAUNCH_BUTTON_TITLE}
                    </span>
                  </Button>
                )}
            </Grid>
            <Grid container item className={classes.toolInfo}>
              {!_.isUndefined(data.platform) && data.platform.join(" • ")}
            </Grid>

            <Grid container item className={classes.longDescription}>
              <div dangerouslySetInnerHTML={{ __html: modifiedLongDescr }} />
            </Grid>
            {!_.isNull(data.tools_infourl_path) &&
              !_.isEqual(data.tools_infourl_path, "") &&
              !isExternalUser &&
              (
                <Grid container item className={classes.moreInfoLink}>
                  <div
                    onClick={() => onMoreInfoClick(data.tools_infourl_path)}
                    className={classes.infoLink}
                  >
                    {bundle.TOOLS_MORE_INFO_TITLE}
                  </div>
                </Grid>
              )}
            <Grid container item className={classes.createdBy}>
              <div>{`${bundle.MADE_BY_TEXT} ${data.made_by}`}</div>
            </Grid>
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
